import React from 'react';
import { graphql } from 'gatsby';
import StoreContext from '../context/StoreContext';
import ProductList from '../components/ProductList';

import Ofertasimperdiveis from '../components/Promotion/OfertasImperdiveis';
import Carousel from '../components/Promotion/Carousel';
import Minibanners from '../components/Promotion/Minibanners';
import MinibannersSquare from '../components/Promotion/MinibannersSquare';

import SEO from '../components/Seo';

function IndexPage({ data }) {
  return (
    <div>
      <SEO
        keywords={[
          `Home`,
          `SoStrass`,
          `Strass para Bijuterias`,
          `Calçados`,
          `Confecção`,
          `Artesanato`,
          `Strass`,
          `Corrente de strass`,
          `Artesanato`,
          `Bijuterias`,
          `Zirconia`,
          `Fio de cristal`
        ]}
        title="Strass para Bijuterias, Calçados, Confecção, Artesanato"
      />

      <Carousel />
      <Ofertasimperdiveis />
      <Minibanners />

      <section className="px-2 py-6">
        <StoreContext.Consumer>
          {context => {
            if (!context.state) {
              return <></>;
            }
            return (
              <>
                <div className="py-4">
                  <h1 className="text-center text-lg md:text-4xl font-bold py-2">
                    Produtos em Destaque
                  </h1>
                  <ProductList
                    addProductToCart={(id, values) =>
                      context.addProductToCart(id, values)
                    }
                    products={data.allWcProducts.edges
                      .filter(item => item.node.featured)
                      .slice(0, 10)}
                  />
                </div>

                <div className="py-4">
                  <h1 className="text-center text-lg md:text-4xl font-bold py-2">
                    Produtos Recentes
                  </h1>
                  <ProductList
                    addProductToCart={(id, values) =>
                      context.addProductToCart(id, values)
                    }
                    products={data.allWcProducts.edges.slice(0, 10)}
                  />
                </div>

                <div className="py-4">
                  <h1 className="text-center text-lg md:text-4xl font-bold py-2">
                    Produtos em Promoção
                  </h1>
                  <ProductList
                    addProductToCart={(id, values) =>
                      context.addProductToCart(id, values)
                    }
                    products={data.allWcProducts.edges
                      .filter(item => item.node.sale_price)
                      .slice(0, 10)}
                  />
                </div>
              </>
            );
          }}
        </StoreContext.Consumer>
      </section>

      <MinibannersSquare />
    </div>
  );
}

export const query = graphql`
  query {
    allWcProducts(
      sort: { fields: [date_modified], order: DESC }
      filter: { stock_status: { eq: "instock" } }
    ) {
      edges {
        node {
          id
          wordpress_id
          date_modified
          description
          featured
          images {
            src
          }
          slug
          sku
          short_description
          sale_price
          price
          on_sale
          name
          regular_price
          categories {
            wordpress_id
            slug
            name
          }
          attributes {
            id
            name
            options
            position
            visible
          }
        }
      }
    }
  }
`;

export default IndexPage;
