import React, { Component } from 'react';
import { Link } from 'gatsby';


import bannerMosaicBase from "../../images/banners/categories/sav.banner-mosaic-base-conica.jpg"
import bannerMosaicBasereta from "../../images/banners/categories/sav.banner-mosaic-base-reta.jpg"
import bannerMosaicCorrente from "../../images/banners/categories/sav.banner-mosaic-corrente-strass.jpg"
import bannerMosaicMalhas from "../../images/banners/categories/sav.banner-mosaic-malhas-strass.jpg"
import bannerMosaicOutros from "../../images/banners/categories/sav.banner-mosaic-outros-componentes.jpg"
import bannerMosaicPerolas from "../../images/banners/categories/sav.banner-mosaic-perolas.jpg"
import bannerMosaicPrimavera from "../../images/banners/categories/sav.banner-mosaic-primavera-verao.jpg"
import bannerMosaicRondele from "../../images/banners/categories/sav.banner-mosaic-rondele-redondo.jpg"
import bannerMosaicCrystal from "../../images/banners/categories/sav.banner-mosaic-fio-de-cristal-facetado.jpg"
import bannerMosaicZirconia from "../../images/banners/categories/sav.banner-mosaic-pedras-de-zirconia.jpg"



export default ({ children }) => (
<div>
  <div className="md:flex md:flex-row justify-around">
    <div>
      <Link to="/categoria/strass-base-conica">
        <img
          async="on"
          alt="banner bannerMosaicBase"
          className="p-2 object-scale-down"
          src={ bannerMosaicBase }
        />
      </Link>
    </div>
    <div>
      <Link to="/categoria/strass-base-reta">
        <img
          async="on"
          alt="banner bannerMosaicBasereta"
          className="p-2 object-scale-down"
          src={ bannerMosaicBasereta }
        />
      </Link>
    </div>
    <div>
      <Link to="/categoria/zirconias">
        <img
          async="on"
          alt="banner bannerMosaicZirconia"
          className="p-2 object-scale-down"
          src={ bannerMosaicZirconia }
        />
    </Link>
    </div>
    <div>
      <Link to="/categoria/fio-de-crystal">
        <img
          async="on"
          alt="banner bannerMosaicCrystal"
          className="p-2 object-scale-down"
          src={ bannerMosaicCrystal }
        />
    </Link>
    </div>
  </div>
</div>
);
