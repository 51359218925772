import React, { Component } from 'react';
import { Link } from 'gatsby';


import minibanner1 from "../../images/banners/banner-tv-monte-suas-proprias-joias.jpg"
import minibanner2 from "../../images/banners/banner-tv-lapis-pega-strass.jpg"
import minibanner3 from "../../images/banners/banner-tv-strass-base-conica.jpg"
import minibanner4 from "../../images/banners/banner-tv-caneta-aplicar-hot-fix.jpg"

export default ({ children }) => (
    <div className="hidden md:flex flex-row justify-around py-2 px-2">

      <div>
        <Link to="/categoria/zirconias/" alt="">
          <img
            async="on"
            alt="banner"
            className="pr-2 object-scale-down"
            src={ minibanner1 }
            />
        </Link>
      </div>

      <div>
        <Link to="/produto/lapis-pega-strass/" alt="">
          <img
            async="on"
            alt="banner"
            className="pr-2 object-scale-down"
            src={ minibanner2 }
            />
        </Link>
      </div>

      <div>
        <Link to="/categoria/strass-base-conica/" alt="">
          <img
            async="on"
            alt="banner"
            className="pr-2 object-scale-down"
            src={ minibanner3 }
            />
        </Link>
      </div>

      <div>
        <Link to="/produto/caneta-de-aplicar-strass-hot-fix-termocolante" alt="">
          <img
            async="on"
            alt="banner"
            className="pr-2 object-scale-down"
            src={ minibanner4 }
            />
        </Link>
      </div>

    </div>
);
