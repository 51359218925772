import React, { Component } from 'react';
import { Link } from 'gatsby';
import ReactDOM from 'react-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

// import bannerballom from '../../images/banners/bannet-tv-ballom-tweet.webp';
// import bannerliquidacao from '../../images/banners/banner-tv-liquidacao-queima-estoque-preciosa2.webp';
import bannercorrentes from '../../images/banners/20211221-banner-correntes.webp';
import bannerdisco from '../../images/banners/20211221-banner-disco-fimo-emborrachado.webp';
import bannermicanga from '../../images/banners/20211221-banner-micanga-fimo.webp';

import bannerzirconias from '../../images/banners/banner-tv-zirconias-caixarias.jpg';
import bannerstrass from '../../images/banners/banner-tv-strass-base-reta.jpg';
import bannerperola from '../../images/banners/bannet-tv-perolas-coloridas.jpg';
import bannerletras from '../../images/banners/banner-tv-pingentes-letras.jpg';

export default ({ children }) => (
  <div className="carousel-outer hidden sm:block bg-gray-300 font-serif">
    <Carousel
      autoPlay
      emulateTouch
      infiniteLoop
      showStatus={false}
      showThumbs={false}
      useKeyboardArrows
    >
      <Link to={`/categoria/correntarias`}>
        <div>
          <img
            alt="banner-correntes"
            async="on"
            className="block object-scale-down"
            src={bannercorrentes}
          />
        </div>
      </Link>

      <Link to={`/categoria/disco-fimo-emborrachado`}>
        <div>
          <img
            alt="banner-disco-fimo-emborrachado"
            async="on"
            className="block object-scale-down"
            src={bannerdisco}
          />
        </div>
      </Link>

      <Link to={`/categoria/micangas-emborrachadas`}>
        <div>
          <img
            alt="banner-micanga-fimo"
            async="on"
            className="block object-scale-down"
            src={bannermicanga}
          />
        </div>
      </Link>

      {/* <Link to={`/categoria/pingente-letra`}>
        <div>
          <img
            alt="banner letras"
            async="on"
            className="block object-scale-down"
            src={bannerletras}
          />
        </div>
      </Link>

      <Link to={`/categoria/perolas`}>
        <div>
          <img
            alt="banner perolas"
            async="on"
            className="block object-scale-down"
            src={bannerperola}
          />
        </div>
      </Link>

      <Link to={`/categoria/zirconia-coracao`}>
        <div>
          <img
            alt="banner zirconiz coracao"
            async="on"
            className="block object-scale-down"
            src={bannerzirconias}
          />
        </div>
      </Link>

      <Link to={`/categoria/strass-base-reta`}>
        <div>
          <img
            alt="banner strass base reta"
            async="on"
            className="block object-scale-down"
            src={bannerstrass}
          />
        </div>
      </Link> */}
    </Carousel>
  </div>
);
